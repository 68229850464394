import store from 'store'

const USER_KEY = 'user_key'
const PRODUCT_ID_KEY = 'product_id_key'

/**
 * 存储user
 */
function saveUser(user) {
  store.set(USER_KEY, user)
}

/**
 * 存储ProcutDetail
 */
function saveProcutId(productId) {
  store.set(PRODUCT_ID_KEY, productId)
}


/**
 * 获取user
 */
function getUser() {
  // return JSON.parse(localStorage.getItem(USER_KEY) || '{}')
  return store.get(USER_KEY) || undefined
}

/**
 * 获取PRODUCT_DETAIL
 */
function getProcutId() {
  // return JSON.parse(localStorage.getItem(USER_KEY) || '{}')
  return store.get(PRODUCT_ID_KEY) || undefined
}

/**
* 删除user
*/
function removeUser() {
  // localStorage.removeItem(USER_KEY)
  store.remove(USER_KEY)
}


export {
  saveUser, getUser, removeUser, getProcutId, saveProcutId
}
