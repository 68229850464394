import React from 'react'
import { Breadcrumb, Layout, Menu, Image } from 'antd';
import {
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { List } from 'immutable';
import AddOrder from './order/AddOrder';
import UpdateOrder from './order/UpdateOrder';
import TreeDemo from './order/TreeDemo';
const { Header, Content, Footer, Sider } = Layout;

const breadCrumb = {
  "addOrder":["订单管理","创建订单"],
  "updateOrder":["订单管理","修改订单"]
}

class SupplyChainIndex extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      selectKey: "addOrder",
    }
  }

  _onClick(e) {
    if (e.key !== this.state.selectKey) {
      this.setState({ selectKey: e.key })
    }
  }

  getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  _getContent(){
    if(this.state.selectKey === 'addOrder'){
      return <AddOrder />
    }else if(this.state.selectKey === 'updateOrder'){
      return <TreeDemo />
    }
    return <AddOrder />
  }
  

  render() {

    const items = [
      this.getItem('订单管理', 'orderManager', <UserOutlined />, [
        this.getItem('创建订单', 'addOrder'),
        this.getItem('修改订单', 'updateOrder'),
        this.getItem('XXXX', '4'),
      ]),
      this.getItem('物料管理', 'sub2', <TeamOutlined />, [this.getItem('Team 1', '6'), this.getItem('Team 2', '8')]),
      this.getItem('核价异议', '9', <FileOutlined />),
      this.getItem('发货管理', '10', <PieChartOutlined />),
    ];

    return (
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider collapsible collapsed={this.state.isCollapsed} onCollapse={(value) => this.setState({ isCollapsed: value })}>
          <Image
            preview={false}
            width={"100%"}
            // height="120px"
            src='catpapaW.png'></Image>

          <Menu theme="dark" defaultSelectedKeys="orderManager" mode="inline" items={items} onClick={this._onClick.bind(this)} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
            }}
          >

          </Header>
          <Content
            style={{
              margin: '0 16px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              { List(breadCrumb[this.state.selectKey]).map(value=><Breadcrumb.Item>{value}</Breadcrumb.Item>)}
              {/* <Breadcrumb.Item>订单管理</Breadcrumb.Item> */}
              {/* <Breadcrumb.Item>创建订单</Breadcrumb.Item> */}
            </Breadcrumb>
            {this._getContent()}
          </Content>
          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            Made with ❤️ by CATPAPA
          </Footer>
        </Layout>
      </Layout>
    );
  }

}

export default SupplyChainIndex;