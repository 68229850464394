import React from 'react'
import { Routes, Route, BrowserRouter } from "react-router-dom";

import './App.css';
import SupplyChainIndex from './components/supplychain/SupplyChainIndex';

import { getUser, removeUser, saveUser } from './components/util/store';


function lazyWithPreload(factory) {
  const Component = React.lazy(factory);
  Component.preload = factory;
  return Component;
}

const ProductDetail = lazyWithPreload(() => import("./components/content/ProductDetail"))
const Manager = lazyWithPreload(() => import("./components/manager/Manager"))
const ProductContent = lazyWithPreload(() => import("./components/content/ProductContent"))
const Register = lazyWithPreload(() => import("./components/user/Register"))
const Login = lazyWithPreload(() => import("./components/user/Login"))
const UserCar = lazyWithPreload(() => import("./components/user/UserCar"))
const Foot = lazyWithPreload(() => import("./components/home/Foot"))
const Home = lazyWithPreload(() => import("./components/home/Home"))
const CatpapaIndex = lazyWithPreload(() => import("./components/CatpapaIndex"))

let user = getUser();

class App extends React.Component {


  constructor(props) {
    super(props);
    if (user === undefined) {
      user = {}
    }
    this.state = {
      user: user,
      currentProductId: "",
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<React.Suspense fallback={<></>}><CatpapaIndex /></React.Suspense>} />
            <Route exact path="/productContent" element={
              <React.Suspense fallback={<></>}>
                <Home user={this.state.user} loginOut={this.loginOut.bind(this)} />
                <ProductContent
                  user={this.state.user}
                />
                <Foot />
              </React.Suspense>
            } />
            <Route exact path="/supplyChain" element={<React.Suspense fallback={<></>}><SupplyChainIndex /></React.Suspense>} />
            <Route exact path="/register" element={<React.Suspense fallback={<></>}><Register setUser={this.setUser.bind(this)} /></React.Suspense>} />
            <Route exact path="/login" element={<React.Suspense fallback={<></>}><Login setUser={this.setUser.bind(this)} /></React.Suspense>} />
            <Route exact path="/userCar" element={<React.Suspense fallback={<></>}><UserCar user={this.state.user} /></React.Suspense>} />
            <Route exact path="/productManager" element={<React.Suspense fallback={<></>}><Manager user={this.state.user} /></React.Suspense>} />
            <Route exact path="/productDetail" element={<React.Suspense fallback={<></>}><ProductDetail /></React.Suspense>} />
          </Routes>
        </BrowserRouter>

      </>
    )
  }

  setUser(user) {
    saveUser(user)
    this.setState({ user: user })
  }

  // 用户登出
  loginOut() {
    this.setState({ user: {} })
    removeUser()
  }
}

export default App;
