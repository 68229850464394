import { Col,  Row, Tree } from 'antd';
import React from 'react'
import { DownOutlined } from '@ant-design/icons';


class AddOrder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const treeData = [
      {
        title: '在途',
        key: '0-0',
        children: [
          {
            title: '在制',
            key: '0-0-0',
            children: [
              {
                title: '待接单',
                key: '0-0-0-0',
              },
              {
                title: '生成准备',
                key: '0-0-0-1',
              },
              {
                title: '裁剪',
                key: '0-0-0-2',
              },
            ],
          }
        ],
      },
      {
        title: '已发货',
        key: '0-1',
      },
      {
        title: '已完成',
        key: '0-2',
      }
   
    ];
    return (
      <>
        <Row>
          <Col span={4}>
            <Tree 
            defaultExpandAll
            switcherIcon={<DownOutlined />}
            // defaultExpandedKeys={['0-0-0']}
            treeData={treeData}
            />
          </Col>
        </Row>
      </>
    );
  }

}

export default AddOrder;