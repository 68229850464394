import { Affix, Carousel, Col, Divider, Image, Row } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';


class UpdateOrder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <>
        UpdateOrder
      </>
    );
  }

}

export default UpdateOrder;